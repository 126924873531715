import crudActions from '@/libs/storeConstActions'
import store from '@/store'

const endpoint = () => `products/${store.state.catalogs?.catalog?.id}/components`

const Actions = crudActions(endpoint)

export default {
  ...Actions,
}
