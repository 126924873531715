export default {
  GET(state, data) {
    state.component = { ...data }
  },
  LIST(state, data) {
    state.components = data
  },
  SET_FORM(state, data) {
    state.componentForm = { ...data }
  },
}
