<template>
  <router-view />
</template>

<script>

import store from '@/store'
import componentsModule from '@/store/main/catalog/components'
import { onUnmounted } from '@vue/composition-api/dist/vue-composition-api'

export default {
  name: 'Components',
  created() {
    this.$emit('updateMenu', 'home-catalog-components')
  },
  setup() {
    const MODULE_NAME = 'components'
    if (!store.hasModule(MODULE_NAME)) store.registerModule(MODULE_NAME, componentsModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(MODULE_NAME)) store.unregisterModule(MODULE_NAME)
    })
  },
}
</script>

<style scoped>

</style>
